import React, { useState, useEffect } from "react";
import { Container, Spinner, Row, Col, Form } from 'react-bootstrap';
import ExchangesTable from './components/ExchangesTable'
import Treemap from "./charts/ExchangeTreeMap";
import { ReactComponent as Caret } from './components/caret-up-fill.svg';
import { doFetchJson } from "./utils/api";

const Exchanges = (props) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false)
  const [exchanges, setExchanges] = useState([])
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  useEffect(() => {
    const getSummary = () => {
      fetch(process.env.REACT_APP_API_URL + "/v1/exchanges/all")
        .then(res => doFetchJson(res))
        .then(res => {
          setExchanges(res)
          setIsLoaded(true)
        })
        .catch((error) => {
          setError(error)
          setIsLoaded(true)
        })
      Array.from(document.getElementsByClassName('toupdate')).forEach((el) => el.classList.add('updated'));
    };
    let timer = setInterval(() => (getSummary()), 15000);
    document.title = "Ranking de corretoras brasileiras de criptomeodas por volume · MercadoCripto"
    getSummary();
    return () => { clearInterval(timer); }
  }, []);

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  let volumevar = ((exchanges.reduce((a, e) => a + e.QuoteVolume, 0)) - (exchanges.reduce((a, e) => a + e.YesterdayVolume, 0))) / (exchanges.reduce((a, e) => a + e.YesterdayVolume, 0));
  if (error) {
    return <Container className="py-3"><h5 className="mt-5 pt-5 text-center text-muted">{error.message}</h5></Container>;
  } else if (!isLoaded) {
    return <Container className="text-center p-5"><br /><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
  } else {
    return (
      <>
        <div className="bg-light border-bottom pb-3">
          <Container className="pt-4">
            <h1 className="h3 text-center text-md-left">Corretoras Nacionais</h1>
            <Row className="pt-3 text-center">
              <Col md={4} sm={5}>
                <h2 className="h6 text-muted">Volume (BRL&nbsp;24h)</h2>
                <div className="d-none d-lg-block">
                  <span className="h5">{exchanges.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                  {volumevar >= 0
                    ? <span className="h6 text-success text-nowrap"> <Caret className="icontable" />{volumevar.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </span>
                    : <span className="h6 text-danger text-nowrap"> <Caret className="icon-rotated" />{volumevar.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)} </span>}
                </div>
                <div className="d-lg-none">
                  <span className="h6">{exchanges.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                  {volumevar >= 0
                    ? <span className="h6 text-success text-nowrap small"> <Caret className="icontable" />{volumevar.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </span>
                    : <span className="h6 text-danger text-nowrap small"> <Caret className="icon-rotated" />{volumevar.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)} </span>}
                </div>

              </Col>
              <Col md={4} className="text-center d-none d-md-block">
                <h3 className="h6 text-muted">Corretoras</h3>
                <span className="h5">{exchanges.length}</span>
              </Col>
              <Col lg={4} sm={6} className="pt-2">
                <Form>
                  <Row className="mx-auto d-lg-none w-50">
                    <Col className="mx-0 px-0 "><span className={isSwitchOn ? 'text-muted' : 'text-bold'}>Tabela</span></Col>
                    <Col className="mx-0 px-0"><Form.Check type="switch" id="table-map" aria-label="Treemap" defaultChecked={isSwitchOn} checked={isSwitchOn} onChange={onSwitchAction} class="d-lg-none custom-control-input" /></Col>
                    <Col className="mx-0 px-0"><span className={!isSwitchOn ? 'text-muted' : 'text-primary'}>Gráfico</span></Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>

        {!isSwitchOn ?
          <Container className="overflow-auto pt-4 ">
            <Row>
              <Col md={6}>
                <ExchangesTable data={exchanges} id="table" />
              </Col>
              <Col md={6} className="d-none d-lg-block">
                <Treemap data={exchanges} />
              </Col>
            </Row>
          </Container>
          :
          <Treemap data={exchanges} />
        }

      </>
    );
  }
}

export default Exchanges;
import React from "react";
import { Container, Spinner, Row, Col, Breadcrumb, ListGroup } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ExchangeSummaryTable from './components/ExchangeSummaryTable'
import NotFound from "./components/NotFound";
import VolChart from "./charts/VolChart";
import exchangelogo from "./components/logos/exchangelogo.svg";
import { doFetchJson } from "./utils/api";

class ExchangeSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      exchange: []
    };
  }

  componentDidMount() {
    this.getSupportedExchanges();
    this.getSummary();
    this.timer = setInterval(() => (this.getSummary()), 15000);
    document.title = this.props.match.params.exchange + " - Dados de volume e preço das criptomoedas negociadas em real brasileiro na corretora · MercadoCripto"
  }

  getSupportedExchanges() {
    var propsexchange = this.props.match.params.exchange
    fetch(process.env.REACT_APP_API_URL + "/v1/exchanges/")
      .then(res => doFetchJson(res))
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            supported_exchanges: result.filter(function (entry) {
              return entry.Name === propsexchange
            })[0]
          })
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  getSummary() {
    fetch(process.env.REACT_APP_API_URL + "/v1/ticker/exchanges/" + this.props.match.params.exchange)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            exchange: result
          })
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
    Array.from(document.getElementsByClassName('toupdate')).forEach((el) => el.classList.add('updated'));
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.exchange !== prevProps.match.params.exchange) {
      this.setState({
        isLoaded: false,
      })
      this.getSummary();
      document.title = this.props.match.params.exchange + " - Dados de volume e preço das criptomoedas negociadas em real brasileiro na corretora · MercadoCripto"
    }
    else { }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
    clearInterval(this.timer);
  }

  render() {
    const { error, isLoaded, exchange, supported_exchanges } = this.state;
    if (error) {
      return <Container className="py-3"><h5 className="mt-5 pt-5 text-center text-muted">Erro no servidor. Volte mais tarde.</h5> {console.log(error.message)}</Container>;
    } else if (!isLoaded) {
      return <Container className="text-center p-5"><br /><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
    } else if (!supported_exchanges) {
      return (<><NotFound /></>)
    } else {
      return (
        <>
          <div className="bg-light border-bottom pb-1">
            <Container><div className="mx-1">
              <Breadcrumb className="pt-2">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/corretoras" }}>
                  Corretoras
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{this.props.match.params.exchange}</Breadcrumb.Item>
              </Breadcrumb>
              <h1 className="h2 text-left">
                <svg className="exchangelogo mr-2" role="img" aria-labelledby="title">
                  <title id="title">{this.props.match.params.exchange}</title>
                  <use href={exchangelogo + "#" + (this.props.match.params.exchange.toLowerCase())}></use>
                </svg>
                <span className="align-middle mx-2">{this.props.match.params.exchange}</span>
              </h1>
              <div className="mt-4">
                <Row>
                  <Col md={6} lg={4}>
                    <ListGroup variant="flush" horizontal className='border-bottom my-2'>
                      <ListGroup.Item className="me-auto border-0 h6 text-muted">Vol 24h <small>(pares BRL)</small></ListGroup.Item>
                      <ListGroup.Item className="border-0 h6">{exchange.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</ListGroup.Item>
                    </ListGroup>
                    <ListGroup variant="flush" horizontal className='border-bottom my-2'>
                      <ListGroup.Item className="me-auto mb-0 border-0 h6 text-muted">No.pares BRL</ListGroup.Item>
                      <ListGroup.Item className="border-0 mb-0 h6">{exchange.length}</ListGroup.Item>
                    </ListGroup>
                    <ListGroup variant="flush" horizontal className='border-0 my-2'>
                      <ListGroup.Item className="me-auto border-0 h6 text-muted">Link</ListGroup.Item>
                      <ListGroup.Item className="border-0 h6"><a href={supported_exchanges.URL !== null ? supported_exchanges.URL : "#"} rel="nofollow noopener noreferrer" target="_blank" className="text-muted pl-1">{supported_exchanges.URL !== null ? supported_exchanges.URL.substring(8) : ""}</a></ListGroup.Item>
                    </ListGroup>
                  </Col>
                  <Col md={6} lg={4}>
                  <VolChart exchange={this.props.match.params.exchange} />
                  </Col>
                </Row>
              </div>
            </div>
            </Container>
          </div>
          <Container>
            <ExchangeSummaryTable data={exchange} full={exchange.reduce((a, e) => a + e.QuoteVolume, 0)}/>
          </Container>
        </>
      );
    }
  }
}
export default ExchangeSummary;
import React, {useState, useEffect} from 'react';
import { ReactComponent as ArrowUp } from './svg/arrow_up.svg';

const ScrollToTop = () => {
    const [visible, setVisible] = useState(0)

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible);
        return () => {
            setVisible({});
    }}, []);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(1)
        }
        else if (scrolled <= 300) {
            setVisible(0)
        }
    };


    function ScrolltoTopFunction() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (
        <div id="topBtn" className="rounded-lg text-white btn btn-secondary shadow-sm scrolltotop" style={{ opacity: visible ? 1 : 0  }} onClick={() => ScrolltoTopFunction()}>
            <ArrowUp style={{ right: "0" }} />
        </div>
    );
}

export default ScrollToTop;
import React from "react";
import { ListGroup } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import logo from './logos/mercadocripto-logo.png';


const Footer = () => (
    <footer className="bg-light border-top">
    <div className="container">
        <img src={logo} className="mt-3 "  alt="mercadocripto" title="mercadocripto" width="170" height="25.5" style={{ webkitFilter: "invert(100%)", filter: "invert(100%)" }} />
        </div>
        <ListGroup id="footer" horizontal className="py-3 justify-content-evenly">
            <ListGroup.Item className="footerlink nav-link border-0" activeClassName="active" as={NavLink} to="/contato">Contato</ListGroup.Item>
            <ListGroup.Item className="footerlink nav-link border-0" activeClassName="active" as={NavLink} to="/metodologia">Metodologia</ListGroup.Item>
            <ListGroup.Item className="footerlink nav-link border-0" activeClassName="active" as={NavLink} to="/widget">Widget</ListGroup.Item>
            <ListGroup.Item className="footerlink nav-link border-0 d-sm-none d-md-block"></ListGroup.Item>
        </ListGroup>
    </footer>
)
export default Footer
import React from 'react';
import Chart from 'react-apexcharts'
import { Container, Spinner, Row, Col } from 'react-bootstrap';
import coinlogo from "../components/logos/coinlogo.svg";
import { doFetchJson } from '../utils/api';
import Error from '../utils/Error.js';

class MonthReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      rawreport: [],
      series: [],
    }

    window.Apex = {
      chart: {
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        axisBorder: {
          color: '#bbc7d200',
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '11px',
          },
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        forceNiceScale: true,
        crosshairs: {
          show: false
        },
      },
      noData: {
        text: 'Carregando...'
      },
      grid: {
        padding: {
          left: -30
        },
        strokeDashArray: 2,
      },
    };

  };

  groupBy(lista, column) {
    var columns = {};
    var resultado = [];

    lista.forEach(function (item) {
      var reg = {};

      columns[item[column]] = columns[item[column]] || [];

      for (var i in item)
        if (i !== column)
          reg[i] = item[i];

      columns[item[column]].push(reg);
    });

    for (var i in columns)
      resultado.push({ name: i, data: columns[i].map(({ Volume }) => Volume) }).toFixed(5);

    return resultado;
  }

  async fetchReport() {
    var symbol = this.props.symbol.split("=").pop();
    await fetch((process.env.REACT_APP_API_URL) + '/v1/ticker/monthreport' + this.props.symbol + '&month=' + this.props.month + '&year=' + this.props.year)
      .then(response => doFetchJson(response, { notFound: "Não foram encontrados dados mensais para os parâmetros informados!" }))
      .then(response => {
        var monthreport = this.groupBy(response, "Exchange");
        var xaxistime = response.map((value) => value.Time).filter((value, index, _arr) => _arr.indexOf(value) === index);
        for (let item of monthreport) {
          while (item.data.length < xaxistime.length) {
            item.data.unshift(0);
          }
        };
        var rawreport = response;
        this.setState({
          isLoaded: true,
          rawreport: rawreport,
          series: monthreport,
          xaxistime: xaxistime,
          options: {
            plotOptions: {
              bar: {
                borderRadius: 5,
                columnWidth: '45%',
              }
            },
            chart: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              type: 'bar',
              height: 350,
              stacked: true,
              toolbar: {
                show: true
              },
            }, theme: {
              mode: 'light',
              palette: 'palette2',
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }],
            legend: {
              width: '150',
              fontSize: '10px',
              position: 'right',
              offsetY: 40
            },
            stroke: {
              width: 0.5, curve: 'smooth', lineCap: "butt", colors: ['#fff'],
            },

            tooltip: {
              shared: false, followCursor: true,
              marker: {
                show: true,
              },
              x: {
                show: true,
                format: 'dd/MM/yyyy',
              },
              y: {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                  return parseFloat(value.toFixed(2)).toLocaleString('pt-BR') + " " + symbol.slice(0, -3)
                },
              }
            },
            crosshairs: {
              show: false,
            },
            xaxis: {
              tickPlacement: 'between',
              type: 'datetime',
              categories: xaxistime,
            },
            yaxis: {
              tickAmount: 4,
              decimalsInFloat: 0,
              labels: {
                offsetX: -30,
                minWidth: 90,
                style: {
                  colors: '#737980',
                }
              },
            },
          }
        },
        )
      }, (error) => {
        this.setState({
          error,
        });
      });
  }

  componentDidMount() {
    this.fetchReport();
  }

  componentDidUpdate(prevProps) {
    if (this.props.symbol !== prevProps.symbol) {
      this.setState({
        isLoaded: false,
        error: null
      })
      this.fetchReport();
    }
    else if (this.props.month !== prevProps.month) {
      this.setState({
        isLoaded: false,
        error: null
      })
      this.fetchReport();
    }
    else if (this.props.year !== prevProps.year) {
      this.setState({
        isLoaded: false,
        error: null
      })
      this.fetchReport();
    }
    else { }
  }

  render() {
    const { error, rawreport, isLoaded, series, xaxistime } = this.state;
    if (error) {
      return <Error error={error} />
    } else if (!isLoaded) {
      return <Container className="text-center p-5"><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
    } else {
      var symbol = this.props.symbol.split("=").pop();
      return (
        <>
          <Row className="my-4">
            <Col md={3} className="bg-light rounded border p-3 mr-4 ml-2">
              <div className="float-left mr-3">
                <svg className="coinlogo mb-1" role="img" aria-labelledby="title">
                  <title id="title">{symbol}</title>
                  <use href={coinlogo + "#" + (symbol.slice(0, -3)).toLowerCase() + "-logo"}></use>
                </svg>
              </div>
              <div>
                <h2 className="lead text-muted" style={{ fontWeight: "500", fontSize: "17px" }}>
                  {symbol === "BRLBRL" ? "Todas as criptomoedas" : symbol }
                </h2>
                <p className="h5 text-dark monthcase">{new Date(xaxistime[0]).toLocaleString('pt-BR', { month: 'long', year: 'numeric' })}</p>
              </div>
            </Col>
            <Col md={4} className="bg-light rounded border p-3 mr-4 ml-2">
              <h2 className="lead text-muted" style={{ fontWeight: "500", fontSize: "17px" }}>Volume mensal</h2>
              <span className="h5 text-dark text-center">{rawreport.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span><br />
              <span className="h6 text-muted text-center"> {(rawreport.reduce((a, e) => a + e.Volume, 0) !== 0) ? rawreport.reduce((a, e) => a + e.Volume, 0).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 0 }) + ' '+ symbol.slice(0, -3):''}</span>
            </Col>
            <Col md={3} className="bg-light rounded border p-3">
              <h3 className="lead text-muted" style={{ fontWeight: "500", fontSize: "17px" }}>Número de corretoras</h3>
              <span className="h5 text-dark text-center">{series.length}</span><br />
            </Col>
          </Row>
          <Container className="pt-4">
            <Chart options={this.state.options} series={this.state.series} type="bar" height={350} />
          </Container>
        </>
      );
    }
  }
}
export default MonthReport;
import React from "react";
import { Popover, OverlayTrigger, Button } from 'react-bootstrap';
// import { ReactComponent as Etoro } from './svg/etoro.svg';
import { ReactComponent as Bitso } from "./svg/bitso.svg";

function shuffle(array) {
  let currentIndex = array.length, randomIndex;

  while (currentIndex !== 0) {

    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
  return array;
}

const ads = [
  // {
  //   id: 1,
  //   title: <Rocket className="mb-1 mt-2 pb-2 w-25 mx-auto" />,
  //   description: 'Entre em contato para anunciar nesse espaço.',
  //   link: '/contato',
  //   icon: '',
  //   buttonvariant: 'greenlime',
  //   buttontext: 'Anuncie aqui!',
  //   fullname: 'Anuncie',
  // },
  // {
  //   id: 2,
  //   // eslint-disable-next-line
  //   title: <img src="https://ad.doubleclick.net/ddm/ad/N1224350.4398694LIVECOINS/B27370993.330488136;sz=1x1;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755}?" border="0" width="1" height="1" alt="Advertisement" />,
  //   description: 'Compre e invista em criptomoedas com segurança na eToro.',
  //   link: 'https://ad.doubleclick.net/ddm/clk/522678460;330488136;l',
  //   icon: <Etoro className="mx-4 mb-1 pb-2" />,
  //   buttonvariant: 'greenlime',
  //   buttontext: 'Compre agora!',
  //   fullname: 'eToro',
  // },
  //   {
  //   id: 3,
  //   title: '',
  //   description: 'Com o Astropay, é fácil e seguro e comprar criptomoedas!',
  //   link: 'https://control.kochava.com/v1/cpi/click?campaign_id=koastropay-card-production-web-6icyif15330fa386d5&network_id=12253&site_id=1&device_id=device_id',
  //   icon: <img style={{width:"80%"}}  src="https://app.astropay.com/assets/logo-slogan.svg" alt="AstroPay"/>,
  //   buttonvariant: 'astropay',
  //   buttontext: 'Compre agora!',
  //   fullname: 'Astropay',
  // },
  {
    id: 4,
    title: '',
    description: 'Compre e venda bitcoin ou qualquer uma das outras 45 criptomoedas disponíveis na Bitso.',
    link: 'https://bitso.onelink.me/I2Ya/oti4bmne',
    icon: <Bitso width="80%" className="me-4 mb-1 pb-2" />,
    buttonvariant: 'greenlime',
    buttontext: 'Compre agora!',
    fullname: 'Bitso',
  },
];


const ad = shuffle(ads)[0]

const popover = (
  <Popover id="popover-basic" className="shadow pt-2 pl-2">
    <Popover.Header className="bg-transparent border-0 mb-0 pb-0 small text-white">
      <small className="mt-1 mb-0 small text-muted" >Patrocinado<br /></small>
    </Popover.Header>
    <Popover.Body>
      <a className="text-decoration-none stretched-link text-black text-center" href={ad.link} rel="noopener noreferrer" target="_blank" >
        {ad.icon}
        {ad.description}
      </a>
      <div className="pt-3 text-center">
        <Button variant={ad.buttonvariant} className="px-5 mb-2 link-light" style={{ zIndex: "2", position: "relative" }} href={ad.link} rel="noopener noreferrer" target="_blank">{ad.buttontext}</Button>
      </div>
    </Popover.Body>
  </Popover>
)

const MainAd = () => (
  <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={popover}><Button className="mx-2 sponsored link-light" variant="secondary" size="sm" style={{ fontWeight: "600", fontSize: "90%" }}>Comprar</Button></OverlayTrigger>

)

export default MainAd
import React from "react";
import { ProgressBar } from 'react-bootstrap';
import { Link } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import exchangelogo from "./logos/exchangelogo.svg";

const SymbolMarketTable = (props) => {
    const price = props.price
    const exchanges = props.data
    const columns = [
        {
            dataField: 'Exchange',
            text: 'Corretora',
            sort: true,
            classes: "freeze",
            headerClasses: "freeze",
            formatter: (cell, row, rowIndex, extraData) => {
                return (
                    <>
                        {
                            row.Exchange === "Binance"
                                ? (<>
                                    <svg className="tablelogo mr-2" role="img" aria-labelledby="title">
                                        <title id="title">{row.Exchange}</title>
                                        <use href={exchangelogo + "#" + (row.Exchange.toLowerCase())}></use>
                                    </svg>
                                    <Link to={'/corretoras/' + row.Exchange} className="stretched-link">{row.Exchange}</Link></>)
                                : <Link to={'/corretoras/' + row.Exchange} style={{marginLeft: "2rem"}} className="stretched-link">{row.Exchange}</Link>
                        }
                    </>
                )
            }
        }, {
            dataField: 'Last',
            text: 'Preço',
            sort: true,
            classes: 'toupdate',
            formatter: (cell, row, rowIndex, extraData) => {
                return <span className="text-nowrap">{(row.Last > 0.1) ? row.Last.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ("R$ " + row.Last.toLocaleString('pt-BR', { style: 'decimal', maximumSignificantDigits: 8 }))}</span>
            }
        }, {
            dataField: 'Spread',
            text: 'Ágio',
            sort: true,
            formatter: (cell, row, rowIndex, extraData) => {
                return (
                    <>
                        {
                            (row.Last - price.Last) / price.Last >= 0
                                ? <span className="text-success"> {((row.Last - price.Last) / price.Last).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </span>
                                : <span className="text-danger"> {((row.Last - price.Last) / price.Last).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </span>
                        }
                    </>
                );
            },
            sortValue: (cell, row, rowIndex, extraData) => (row.Last - price.Last) / price.Last

        }, {
            dataField: 'Volume',
            text: 'Volume 24h',
            sort: true,
            formatter: (cell, row, rowIndex, extraData) => {
                return <span className="text-nowrap">{parseFloat(row.Volume).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 0 })} {exchanges[0].Symbol.slice(0, -3)}</span>
            }
        }, {
            dataField: 'VolumePercent',
            text: 'Volume (%)',
            sort: true,
            formatter: (cell, row, rowIndex, extraData) => {
                return (<>
                    <ProgressBar max={Math.max.apply(Math, exchanges.map(function (e) { return e.VolumePercent * 100; }))} now={row.VolumePercent * 100} style={{ minWidth: "2em" }} variant="info" label={`${row.VolumePercent.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 1 })}`} />
                </>)
            }
        }
    ]

    const defaultSorted = [{
        dataField: 'VolumePercent',
        order: 'desc'
    }];

    return (
        <>
            <BootstrapTable
                bordered={false}
                hover
                bootstrap4
                condensed
                keyField="Exchange"
                data={exchanges}
                columns={columns}
                defaultSorted={defaultSorted}
                headerClasses="header-class"
            />
        </>
    )
}
export default SymbolMarketTable
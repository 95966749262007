import React, { Suspense, useState, useEffect } from 'react';
import { Placeholder } from 'react-bootstrap';

const Chart = React.lazy(() => import('react-apexcharts'));

const TreeMap = (props) => {
  const [series, setSeries] = useState([{ name: 'corretoras', data: [] }]);
  const [options, setOptions] = useState({
    noData: {
      text: " ",
      verticalAlign: "middle",
      align: 'center',
    },
    chart: {
      animations: {
        enabled: true,
      },
      type: 'treemap',
      background: '#f8f9fa'
    },
  });
  const updateCharts = () => {
    var chart = props.data.map(x => ({
      'x': x.Name,
      'y': x.QuoteVolume,
      'z': x.VolumePercent
    }));
    setSeries([{ data: chart, name: 'corretoras' }]);
    setOptions({
      chart: {
        animations: {
          enabled: false,
        },
        background: 'transparent'
      },
      dataLabels: {
        style: {
          fontFamily: "Roboto,Helvetica, Arial, sans-serif",
        },
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          return value + "\n" + w.config.series[seriesIndex].data[dataPointIndex].z.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })
        }
      },
      tooltip: {
        enabled: true,
        marker: {
          show: true
        },
        y: {
          formatter: function (value) {
            return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
          }
        },
        z: {show:false,
          formatter: function (value) {
            return value.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })
          },
          title: ''
      },
      }
    })
  }

  useEffect(() => {
    let timer1 = setTimeout(() => updateCharts(), 10);
    return () => { clearTimeout(timer1); };
  })

  if (series[0] === undefined) {
    <span className="text-muted">Erro</span>
  } else {
    return (
      <Suspense fallback={<Placeholder.Button animation="glow" variant="light" style={{ maxHeight: "80vh", maxWidth: "100vw" }} aria-hidden="true" />}>
        <div className="rounded ms-2">
          <Chart
            options={options}
            series={series}
            type="treemap"
            height="500px"
          /></div>
      </Suspense>
    );
  }
}
export default TreeMap;
import React, { useState,useEffect } from "react";
import { Navbar, Offcanvas, Nav, Container, CloseButton } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import SearchPage from "./SearchBar/SearchPage";
import logo from './logos/mercadocripto-logo.png';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Caret } from './caret-up-fill.svg';

const NavBar = () => {
  const [show, setShow] = useState(false);
  const location = useLocation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setShow(false)
  }, [location]);

  return (
    <>
      <Navbar id="topnav" collapseOnSelect expand="lg" bg="primary" variant="dark">
        <Container className="pr-5 pl-2" >
          <Navbar.Brand as={NavLink} to="/" alt="MercadoCripto">
            <img src={logo} className="d-inline-block align-middle" alt="mercadocripto" title="mercadocripto" width="170" height="25.5" />{' '}
          </Navbar.Brand>
          <div className="d-none d-lg-block  me-auto ">
            <Nav className="my-2 my-lg-0justify-content-evenly" style={{ maxHeight: '100px' }}>
              <Nav.Link as={NavLink} to="/" activeClassName="active" exact >Moedas</Nav.Link>
              <Nav.Link as={NavLink} to="/corretoras" activeClassName="active">Corretoras</Nav.Link>
              <Nav.Link as={NavLink} to="/relatorios" activeClassName="active">Dados Mensais</Nav.Link>
            </Nav>
          </div>
          <div className="d-flex mt-2 pb-1 d-none d-lg-block " id="navSearch">
            <SearchPage />
          </div>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleShow} />
          <Navbar.Offcanvas show={show}  onHide={handleClose} id="offcanvasNavbar" className="bg-light" aria-labelledby="offcanvasNavbarLabel" placement="end">
            <Offcanvas.Header id="topnav" className="bg-primary pl-3" style={{paddingTop: "2.2rem", paddingBottom: "2.2rem"}}>
              <Offcanvas.Title id="offcanvasNavbarLabel">
                <img src={logo} className="d-inline-block align-top" alt="mercadocripto" title="mercadocripto" width="170" height="25.5"/>
              </Offcanvas.Title>
              <CloseButton onClick={handleClose} className="float-end mr-0 btn-close-white" />
            </Offcanvas.Header>
            <Offcanvas.Body className="p-0">
            <div className="d-flex py-5 w-100 px-3 border-bottom">
                <SearchPage/>
              </div>
              <Nav className="mr-auto pt-2 my-lg-0 px-3">
                <div className="mr-auto my-4" style={{transform: "rotate(0)"}}><Nav.Link as={NavLink} to="/" className="text-primary stretched-link" activeClassName="active" exact onClick={handleClose}>Moedas</Nav.Link><Caret className="text-primary icon-rotated-offcanvas float-end" /></div>
                <div className="mr-auto my-4" style={{transform: "rotate(0)"}}><Nav.Link as={NavLink} to="/corretoras" className="text-primary stretched-link" activeClassName="active" onClick={handleClose}>Corretoras</Nav.Link><Caret className="text-primary icon-rotated-offcanvas float-end" /></div>
                <div className="mr-auto my-4" style={{transform: "rotate(0)"}}><Nav.Link as={NavLink} to="/relatorios" className="text-primary stretched-link" activeClassName="active" onClick={handleClose}>Dados Mensais</Nav.Link><Caret className="text-primary icon-rotated-offcanvas float-end" /></div>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  )
}
export default NavBar;
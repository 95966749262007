import React, { useState, useEffect } from "react";
import Parser from 'rss-parser';
import { Card, Col, Row, Placeholder } from 'react-bootstrap';


const Newsfeed = (props) => {
    var slug = props.slug
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false)
    const [feed, setFeed] = useState([])

    useEffect(() => {
        async function getFeed() {
            let parser = new Parser();
            try {
                try {
                    const feed = await parser.parseURL(process.env.REACT_APP_API_URL + "/feed/" + slug.toLowerCase().split(' ')[0]);
                    setFeed(feed.items)
                    setIsLoaded(true)
                }
                catch {
                    const feed = await parser.parseURL(process.env.REACT_APP_API_URL + "/feed/altcoins");
                    setFeed(feed.items)
                    setIsLoaded(true)
                }
            }
            catch (error) {
                setError(error)
                setIsLoaded(true)
            }
        };
        getFeed();
    }, [slug]);

    const epochs = [
        ['ano', 31536000],
        ['', 2592000],
        ['dia', 86400],
        ['hora', 3600],
        ['minuto', 60],
        ['segundo', 1]
    ];
    const getDuration = (timeAgoInSeconds) => {
        for (let [name, seconds] of epochs) {
            const interval = Math.floor(timeAgoInSeconds / seconds);
            if (interval >= 1) {
                return {
                    interval: interval,
                    epoch: name
                };
            }
        }
    };
    const timeAgo = (date) => {
        const timeAgoInSeconds = Math.floor((new Date() - new Date(date)) / 1000);
        const { interval, epoch } = getDuration(timeAgoInSeconds);
        const suffix = (interval === 1 && epoch === '') ? 'mês' :
            (interval !== 1 && epoch === '') ? 'meses' :
                (interval === 1 && epoch !== '') ? '' : 's';
        return `${interval} ${epoch}${suffix} atrás`;
    };

    if (error) {
        return <>{console.log(error.message)}</>
    } else if (!isLoaded) {
        return (
            <><h3>Notícias</h3>
                <Row xs={1} md={3} className="g-3 mb-3">
                    {[0, 1, 2].map((i, idx) => (
                        <Col key={i}>
                            <Card className="feed h-100 gy-2">
                                <div className="card-img-top bg-primary opacity-75" />
                                <Card.Body>
                                    <Placeholder as={Card.Title} animation="glow">
                                        <Placeholder xs={6} />
                                    </Placeholder>
                                    <Placeholder as={Card.Text} animation="glow">
                                        <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                                        <Placeholder xs={6} /> <Placeholder xs={8} />
                                        <Placeholder xs={11} /> <Placeholder xs={8} />
                                    </Placeholder>
                                </Card.Body>
                                <Placeholder as={Card.Footer} animation="glow">
                                    <Placeholder xs={7} />
                                </Placeholder>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </>);
    } else if (feed.length > 0) {
        return (
            <>
                <h3>Notícias</h3>
                <Row xs={1} md={3} className="g-3 mb-3">
                    {feed.slice(0, 3).map((i, idx) => (
                        <Col key={idx}>
                            <Card className="feed h-100 gy-2">
                                <Card.Img variant="top" src={!("https://livecoins.com.br/" + i.content.match(/wp.*?.(jpg|png|jpeg|gif)/)[0]) ? '' : ("https://livecoins.com.br/" + i.content.match(/wp.*?.(jpg|png|jpeg|gif)/)[0])} alt={i.title} />
                                <Card.Body>
                                    <Card.Title>{i.title}</Card.Title>
                                    <Card.Text>
                                        <a href={i.link} rel="noopener noreferrer" target="_blank" className="stretched-link" style={{ cursor: "pointer" }}> </a>{i.contentSnippet.slice(0, 190)}...
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <small className="text-muted">{getDuration === undefined ? '1 segundo atrás' : timeAgo(i.isoDate)}</small>
                                </Card.Footer>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </>)
    } else { return (<> </>) }
}


export default Newsfeed;
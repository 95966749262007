import React from "react";
import { Container } from 'react-bootstrap';

const Error = (props) => {
    const error = props.error
    return (
        <Container className="p-5 mt-2 text-center">
            <span className="pb-4 h6 text-muted">{error.message}</span>
        </Container>
    )
}

export default Error